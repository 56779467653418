<template
  ><div class="text-h1 text-secondary text-center q-pa-xl">
    History of Dorado
  </div>
  <div class="timeline-content text-justify">
    <q-timeline layout="comfortable" color="secondary">
      <q-timeline-entry
        title="Annexation of Dorado"
        subtitle="April 12th 1845"
        side="left"
      >
        <div class="text-grey">
          The United States government, driven by manifest destiny, annexes the
          independent territory of Dorado. (mexican american war?)
        </div>
      </q-timeline-entry>
      <q-timeline-entry
        title="Dorado Resistance"
        subtitle="June 2nd 1848"
        color="secondary"
      >
        <div class="text-grey">
          <p>
            Late on a swampy night in the south of Lemoyne county, a group of
            men and their wives gather for a dinner at Braithwaite Manor. At
            this gathering are merchants, land owners and men of means who have
            interests and businesses in Dorado. The topic of discussion is the
            recent annexation of the Dorado Territory after the Mexican American
            War, and the impact the taxation and regulations are having on their
            businesses.
          </p>

          <p>
            Byron Braithewait sits at the head of the table, arguing loudly that
            the freedom of the Dorado people and their ability to conduct their
            business without interference has been taken from them by the greedy
            federalists in the United States government. He urges his guests to
            do what they can in order to create unrest in the face of the
            occupation, to refuse business to U.S. representatives and resist
            until the oppressors remove themselves.
          </p>
          It wouldn’t be until several years later that the discussions and
          plans sparked on this night would evolve into an armed militia
          conducting raids and actions against the standing U.S. military in
          Dorado.
        </div>
      </q-timeline-entry>
      <q-timeline-entry
        color="secondary"
        title="The Treaty of Bear Cave"
        subtitle="1849"
        side="left"
      >
        <div class="text-grey">
          Driven north out of the heartlands and into the mountains the
          fledgling Dorado Resistance finds itself with its back against the
          wall. Seeking refuge in a cave along with several families of
          displaced native americans who had fled south to Dorado to avoid the
          reservations of the United States. Seeing a common goal of freedom and
          a need for support the elders of both sides agree on a treaty,
          pledging support for each other and to drive out the US forces. The
          tribes will forever be welcomed in Dorado and given freedom to exist
          on their own tribal lands.
        </div>
      </q-timeline-entry>
      <q-timeline-entry
        color="secondary"
        title="The Slaughter of Black Water"
        subtitle="1851"
      >
        <div class="text-grey">
          <p>
            The US began to employ some very radical and brutal tactics, due to
            mounting pressure from Washington, to quell this Rebellion in the
            Dorado Territory. Thus the Darkest Day of the War, and the true
            Turning point. Under the command of Col. Richard Brightmore, the
            17th US Army Infantry arrived in Black Water and began to round up
            all of the Men of military age, to the center of town. Once they
            were rounded up, the Army produced two Horse Drawn Gatling guns, and
            killed every single man and boy capable of carrying arms against the
            US.
          </p>
          <p>
            The shock and horror of what had happened, polarized the women of
            black Water. After their husbands and sons had been killed at the
            hands of these Soldiers.. the Infantry set to "liberating" the town
            of supplies. The Unit set up Camp in the Town that night feeling
            they had nothing to Fear.. only that night.. the Widows and Mothers
            took their revenge.. Killing and Slaughtering the entire Unit. Word
            Traveled back to command of what had transpired.
          </p>
          <p>
            The US immediately Called a cease fire, for they couldn't convince
            many of their officers that treating Women as enemy combatants was
            going to happen. So the US withdrew their forces, to their borders.
            And Treaty Papers were Drawn up. Dorado seeing women now as capable
            of great deeds, granted them the full equal rights of any man.
          </p>
        </div>
      </q-timeline-entry>
    </q-timeline>
  </div>
</template>

<script>
export default {};
</script>

<style lang="sass">
.timeline-content
    width: 70%
    margin: 0 auto

.q-timeline__title
    font-family: Headline !important
    font-size: 1.4em !important
    letter-spacing: 2px

.q-timeline__subtitle
    font-size: .8em !important
</style>
