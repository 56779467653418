import { render } from "./History.vue?vue&type=template&id=5d2a26d4"
import script from "./History.vue?vue&type=script&lang=js"
export * from "./History.vue?vue&type=script&lang=js"

import "./History.vue?vue&type=style&index=0&id=5d2a26d4&lang=sass"
script.render = render

export default script
import QTimeline from 'quasar/src/components/timeline/QTimeline.js';
import QTimelineEntry from 'quasar/src/components/timeline/QTimelineEntry.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QTimeline,QTimelineEntry});
